import cn from 'classnames'

import { ICurrency } from 'store/fetchers/currencies'

import { FC } from 'react'

import styles from './styles.module.scss'

export const NetworkLabel: FC<{
  currency: ICurrency
  truncate?: boolean
  className?: string
}> = ({ currency, truncate = false, className }) => {
  const code = currency?.code?.toLowerCase()
  const rawNetwork = currency?.network?.toLowerCase()
  let network = rawNetwork === code ? null : rawNetwork

  if (!network) {
    return <div />
  }

  if (truncate && network?.length > 5) {
    network = network.substring(0, 4) + '...'
  }

  let color = currency?.color_network
  if (!color) {
    color = '#a84ae2'
  }

  return (
    <div
      className={cn(styles.tickerName, className)}
      style={{
        color: color,
        border: `0.5px solid ${color}`,
      }}
    >
      {network?.toUpperCase()}
    </div>
  )
}
